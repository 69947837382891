import React from "react"
import { Container, Button, Col, Media, Image, Row } from "react-bootstrap"
import TopMenu from "../components/Layout/TopMenu"
import { LoremIpsum } from "react-lorem-ipsum"
import Typewriter from "typewriter-effect"
import Footer from "../components/Layout/Footer"

const SecondPage = () => (
  <Container fluid className="home-container p-0">
        <TopMenu></TopMenu>
        <Container fluid className="home-slider">
          <Container fluid="xl" className="home-slider-container d-flex flex-column ">
            <h1>Terms and Rates</h1>
            <Container fluid>
              <p className="placeholder-text">Insert State Map Here</p>
            </Container>
          </Container>
        </Container>
        <Footer></Footer>
  </Container>
)

export default SecondPage
